import { graphql, navigate, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { Col, Row } from "react-bootstrap"

import {
  FaArrowRight,
  FaExternalLinkAlt,
  FaUnlock,
  FaUser,
  FaVideo,
} from "../../../AppReactIcons"
import {
  Dashboard_InternalLink,
  Profile_InternalLink,
} from "../../../utils/urls"
import OnlyWithProfile from "../../QueryUserProfile/OnlyWithProfile"
import makeVideoModalAppButton from "../../VideoModalButton/makeVideoModalAppButton"
import { Button, DarkAppButton, WhiteAppButton } from "../../buttons"
import OnlyForUser from "../../registration/OnlyForUser"
import WhatIsEm from "./WhatIsEm"
import styled from "styled-components"
import { breakpoints } from "../../../utils"

const PunchLine = () => (
  <>
    <strong>AskEM</strong> is your personal guide to early menopause.
  </>
)
const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`
const LinkContainer = styled.div`
  display: none;
  @media (max-width: ${breakpoints.tablet}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
    position: absolute;
    z-index: 1;
  }
`
const IconButton = styled(Button)`
  margin-top: 0px;
  margin-bottom: 10px;
  border-radius: 28px;
  font-size: 1rem;
  &:hover svg {
    stroke: white;
  }
  @media (max-width: ${breakpoints.phone}px) {
    margin: 0 auto;
    display: block;
  }
`
const RightArrowIcon = styled(FaArrowRight)`
  margin: 0;
  stroke: ${props => props.theme.colors.primary};
`

const Heading = () => <>Welcome to Ask Early Menopause.</>
const Content = () => (
  <>
    Menopause occurring in women younger than 40 years of age is called
    premature or if under 45 years is called early menopause (EM). EM affects
    around 12 in 100 women. Premature or early menopause can happen
    spontaneously or be caused by medical treatments such as chemotherapy,
    radiotherapy or surgical removal of both ovaries. Women tell us "Early
    menopause changes you. You physically have changed. You emotionally change.
    You psychologically change".
    <br />
    <br />
    We developed this app ‘with women for women’ to help you find trustworthy
    information of the highest quality from leading experts to help you learn
    about the condition and support you with tools including a personal
    dashboard to help you track your symptoms, find the healthiest possible
    lifestyle and decide on the best management options for you.
    <br />
    <br />
  </>
)

const gqlQueryHeroBgImage_FluidImage = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "hpcompressed.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const HeroSectionStaticQuery: React.FC<{ children: (data: any) => any }> = ({
  children,
}) => {
  return (
    <StaticQuery query={gqlQueryHeroBgImage_FluidImage}>
      {data => children(data)}
    </StaticQuery>
  )
}

export const HeroSectionOnDesktopWrapper: React.FC<{
  children?: object
  imageOnRight?: boolean
  imageFluidData?: any
}> = ({ children, imageOnRight, imageFluidData }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      {!imageOnRight ? (
        <Img className="h-full" fluid={imageFluidData} />
      ) : (
        <React.Fragment />
      )}
      <div className="col-span-1 min-h-full text-site-blue-dark">
        {children}
      </div>
      {imageOnRight ? (
        <Img className="h-full" fluid={imageFluidData} />
      ) : (
        <React.Fragment />
      )}
    </div>
  )
}

const HeroSectionOnDesktop = () => {
  const VideoButton = makeVideoModalAppButton(
    DarkAppButton,
    "youtube",
    "RCSMDJBKKtE",
    {
      title: "Watch video",
      Icon: <FaVideo />,
    }
  )
  return (
    <HeroSectionStaticQuery>
      {data => (
        <Container className="text-site-blue-dark">
          <Col>
            <p className="text-xl sm:text-xl mt-10">
              <PunchLine />
            </p>
            <h1 className="text-4xl sm:text-5xl text-site-blue-dark">
              <Heading />
            </h1>
            <p className="mt-4 mb-0">
              <Content />
            </p>
            <IconButton onClick={() => navigate("/discover")}>
              Start the journey <RightArrowIcon />
            </IconButton>
          </Col>
          <Col style={{ marginTop: "90px" }}>
            <WhatIsEm />
          </Col>
        </Container>
      )}
    </HeroSectionStaticQuery>
  )
}

const HeroSectionOnMobile = () => {
  const VideoButton = makeVideoModalAppButton(
    WhiteAppButton,
    "youtube",
    "RCSMDJBKKtE",
    {
      title: "Watch video",
      Icon: <FaVideo />,
    }
  )

  const DiscoverButton = () => {
    return (
      <div className="flex ml-1">
        <Link to="/discover">
          <div className="mx-1" />
          <WhiteAppButton
            Icon={<FaArrowRight />}
            title="Get started"
            iconOnRight={true}
          />
        </Link>
      </div>
    )
  }
  const LoginButton = () => (
    <div className="flex mr-1">
      <Link to="/login">
        <div className="mx-1" />
        <WhiteAppButton Icon={<FaUser />} title="My dashboard" />
      </Link>
    </div>
  )
  return (
    <HeroSectionStaticQuery>
      {data => (
        <div>
          <div className="">
            <Img
              style={{ maxHeight: "400px" }}
              fluid={data.placeholderImage.childImageSharp.fluid}
            />
            <div className="md:hidden flex items-center justify-center m">
              <LinkContainer>
                <OnlyForUser
                  redirectTo={null}
                  functionalChildren={isUser => {
                    if (isUser)
                      return (
                        <OnlyWithProfile
                          noRedirectToProfilePage={true}
                          hideLoader={true}
                          childrenWithProfileData={data => (
                            <Link
                              to={
                                data
                                  ? Dashboard_InternalLink
                                  : Profile_InternalLink
                              }
                            >
                              <WhiteAppButton
                                Icon={<FaUser />}
                                title="My dashboard"
                              />
                            </Link>
                          )}
                        />
                      )
                    return (
                      <>
                        <LoginButton />
                        <DiscoverButton />
                      </>
                    )
                  }}
                />
              </LinkContainer>
            </div>
          </div>
          <div className="bg-site-blue-dark text-white">
            <div className="container mx-auto py-8 px-6 text-center sm:px-12">
              <p className="text-lg sm:text-xl">
                <PunchLine />
              </p>
              <h1 className="text-4xl sm:text-5xl text-white">
                <Heading />
              </h1>
              <p className="my-5">
                <Content />
              </p>
            </div>
          </div>
          <div className="mt-10">
            <WhatIsEm />
          </div>
        </div>
      )}
    </HeroSectionStaticQuery>
  )
}

const CoverSection = () => (
  <React.Fragment>
    <section className="hidden lg:block">
      <HeroSectionOnDesktop />
    </section>
    <section className="block lg:hidden">
      <HeroSectionOnMobile />
    </section>
  </React.Fragment>
)

export default CoverSection
