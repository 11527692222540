import React from "react"
import styled from "styled-components"
import { FaArrowRight, FaClipboardList } from "react-icons/fa"
import { Link } from "gatsby"

interface Props {
  readonly text: string
  readonly color?: string
  readonly href?: string
  readonly className?: string
}

const getColor = (props: any) =>
  props.color ? props.color : props.theme.colors.secondary

const Container = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: none !important;
  color: #ec4d7b;
`

const AskContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none !important;
  color: white;
  margin-left: 10px;
  height: 43px;
  border-radius: 20px;
  width: 210px;
  background-color: ${props => props.theme.colors.primary};
  position: absolute;
  bottom: 0;

  & :hover {
    color: white;
    background-color: ${props => props.theme.colors.darkPrimary};
  }
`
const AskText = styled.span`
  color: ${props => props.theme.colors.white};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-right: 5px;
`
const AskRightArrowIcon = styled(FaArrowRight)`
  margin: 0;
  vertical-align: middle;
  stroke: ${getColor};
`
const Text = styled.span`
  color: ${getColor};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-right: 5px;
`
const RightArrowIcon = styled(FaArrowRight)`
  margin: 0;
  vertical-align: middle;
  stroke: ${getColor};
`

const RedirectLink: React.FC<Props> = ({ text, color, href, className }) => {
  return (
    // @ts-ignore
    <Container to={href} className={className}>
      <Text color={color}>{text}</Text>
      <RightArrowIcon color={color} />
    </Container>
  )
}

const AskRedirectLink: React.FC<Props> = ({ text, color, href, className }) => {
  return (
    <AskContainer to={href} className={className}>
      <AskText color={color}>{text}</AskText>
      <RightArrowIcon color={color} />
    </AskContainer>
  )
}
export { AskRedirectLink }
export default RedirectLink
