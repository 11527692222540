import { ITopic } from "models/Topic"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FaSearch } from "react-icons/fa"
import SectionTitle from "../../SectionTitle"
import Topic from "../../TopicIcon"
import { breakpoints } from "../../../utils"
import { queryAllYaml } from "utils/staticQuery"

const Container = styled.div`
  margin-top: 1.5rem;
  padding-bottom: 25px;
`
const Title = styled(SectionTitle)`
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.secondary} !important;
  @media (max-width: ${breakpoints.phone}px) {
    justify-content: center;
  }
`
const SearchIcon = styled(FaSearch)`
  width: 1.7rem;
  margin-right: 10px;
  stroke: ${props => props.theme.colors.secondary};
`
const TopicList = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  color: ${props => props.theme.colors.secondary};
  @media (max-width: ${breakpoints.phone}px) {
    justify-content: space-around;
  }
`
const List = styled.div`
  padding: 15px;
`

const CoverSection = () => {
  const [topics, setTopics] = useState<ITopic[]>([])
  const [isMobileView, setIsMobileView] = useState(false)

  const { allTopics } = queryAllYaml()

  const discoverTopics = allTopics.edges.map((item: any) => ({
    ...item.node,
    link: `/discover?topic=${item.node.id}`,
  }))

  useEffect(() => {
    const isMobile = document.body.scrollWidth <= breakpoints.phone
    setIsMobileView(isMobile)
    setTopics([...discoverTopics])
  }, [])

  return (
    <Container id="topics">
      <Title>
        <SearchIcon />
        {!isMobileView ? "Topics to explore..." : "Explore"}
      </Title>
      <TopicList>
        {topics.map(topic => (
          <Topic
            id={topic.id as string}
            icon={topic.icon}
            title={topic.title}
            link={topic.link}
            key={topic.id}
            iconStyles={{
              fill: "#EC4D7B",
            }}
          />
        ))}
      </TopicList>
    </Container>
  )
}

export default CoverSection
