import { Amplify, Auth } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { PuffLoader } from "react-spinners"

import { AWS_CONFIG } from "../../../aws-config"
import ErrorText from "../ErrorText"
import { UserType } from "api/types"
import { apiFetchUser } from "api/queries"
import getProfileFromSessionStorage from "./getProfileFromSessionStorage"
import syncProfileWithSessionStorage from "./syncProfileWithSessionStorage"

const QueryUserProfile: React.FC<{
  children: (data: UserType | null) => any
  fallBackChildren?: (error: string | null) => any
  hideLoader?: boolean
}> = ({ children, fallBackChildren, hideLoader }) => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<UserType | null>(null)

  const fetchUserProfile = async (user_sub: string, email: string) => {
    try {
      const data = await apiFetchUser(user_sub)
      if (!data) {
        setLoading(false)
        setError("Could not load data")
        return
      }
      if (data) {
        setData(data)
        syncProfileWithSessionStorage(data)
      }
      setLoading(false)
    } catch (e) {
      setError(e.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
    Auth.currentAuthenticatedUser()
      .then(data => {
        const userProfileDataFromLocalStorage = getProfileFromSessionStorage()
        if (!userProfileDataFromLocalStorage) {
          fetchUserProfile(data.attributes.sub, data.attributes.email)
          return
        }
        const parsedUserProfileData: UserType = JSON.parse(
          userProfileDataFromLocalStorage
        )
        if (!parsedUserProfileData) {
          fetchUserProfile(data.attributes.sub, data.attributes.email)
          return
        }
        // if (parsedUserProfileData.user_sub !== data.attributes.sub) {
        //   fetchUserProfile(data.attributes.sub)
        //   return
        // }
        //console.log("Fetching data form local store")
        setData(parsedUserProfileData)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(error.message)
      })
  }, [])

  if (error) {
    if (fallBackChildren) return <>{fallBackChildren(error)}</>
    return (
      <div className="flex items-center justify-center my-20">
        <div style={{ maxWidth: "300px" }}>
          <ErrorText text={error} />
        </div>
      </div>
    )
  }
  if (loading && !hideLoader)
    return (
      <div className="flex items-center justify-center my-20">
        <div>
          <div className="flex items-center justify-center">
            <PuffLoader size={35} color="#003b78" />
          </div>
          <p className="py-2 px-4 bg-gray-200 text-gray-700 rounded-full mt-3 leading-none text-xs">
            Loading...
          </p>
        </div>
      </div>
    )
  if (loading && hideLoader) return <></>
  return <>{children(data)}</>
}

export default QueryUserProfile
