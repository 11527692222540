import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FaRegCompass } from "react-icons/fa"
import RedirectLink from "../../RedirectLink"
import SectionTitle from "../../SectionTitle"
import DiscoverCard from "../../DiscoverCard"
import Carousel from "../../Carousel"
import { IArticleMeta } from "models/ArticleMeta"
import { breakpoints } from "../../../utils"
import { queryAllYaml } from "utils/staticQuery"
import { navigate } from "gatsby"

const Container = styled.div`
  margin-top: 50px;

  /* margin: 10px; */

  & .alice-carousel__stage {
    /* margin-right: 5px;
    border-radius: 12px; */

    @media (max-width: ${breakpoints.tablet}px) {
      padding: 0px;
    }
  }
  & .alice-carousel__stage-item {
    border-radius: 12px;
    height: 400px;
  }
  /* @media (max-width: ${breakpoints.phone}px) {
    width: 85%;
  
    border-radius: 12px;
    border: 1px solid red;
    display: flex;
    margin-left: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const SpeakerIcon = styled(FaRegCompass)`
  width: 2.2rem;
  margin-right: 10px;
`
const MoreLink = styled(RedirectLink)`
  display: block;

  @media (max-width: ${breakpoints.phone}px) {
    display: none;
  }
`
const MobileMoreLink = styled(RedirectLink)`
  display: block;
  margin-top: 28px;
  text-align: center;
  margin-top: -55px;

  @media (min-width: ${breakpoints.phone}px) {
    display: none;
  }
`
const ServiceCard = styled(DiscoverCard)`
  width: 279px;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 398px;
    /* @media (max-width: ${breakpoints.phone}px) {
      padding-left: 18px;
      padding-right: 18px;
    } */

    /* margin-left: 50px; */
  }
`

const renderCarouselItems = (items: any[]) => {
  return items.map(item => {
    return <ServiceCard {...item} onClick={() => navigate(item.link)} />
  })
}

const ServiceSection = () => {
  const { discoverArticles } = queryAllYaml()
  const [items, setItems] = useState<IArticleMeta[]>([])
  useEffect(() => {
    const data = discoverArticles.edges.map((article: any) => ({
      ...article.node,
      link: `/articles/${article.node.path}`,
    }))
    setItems(data)
  }, [])

  return (
    <Container>
      <Header>
        <SectionTitle centerInMobile>
          <SpeakerIcon />
          Discover
        </SectionTitle>
        <MoreLink text="View more" href="/discover" />
      </Header>
      {items && items.length ? (
        <Carousel items={renderCarouselItems(items)} infinite />
      ) : null}
      <MobileMoreLink text="View more" href="/discover" />
    </Container>
  )
}

export default ServiceSection
