import React from "react"
import CoverSection from "components/homePage/CoverSection"
import TopicSection from "components/homePage/TopicSection"
import Layout from "components/layout"
import SEO from "components/seo"
import WomenVoiceSection from "components/homePage/WomenVoiceSection"
import ServiceSection from "components/homePage/ServiceSection"
import AskSection from "components/homePage/AskSection"
import InfoSection from "components/homePage/InfoSection"
import styled from "styled-components"
import { breakpoints } from "utils"

const Wrapper = styled.div`
  width: 100%;
  @media (max-width: ${breakpoints.extraLargeDesktop}px) {
    max-width: 1552px;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Wrapper>
      <CoverSection />
      <TopicSection />
      <WomenVoiceSection />
      <ServiceSection />
      <AskSection />
      <InfoSection />
    </Wrapper>
  </Layout>
)

export default IndexPage
