import React from "react"
import styled from "styled-components"
// import { GoPrimitiveDot } from "react-icons/go"
import Img from "gatsby-image"
import { IArticle } from "api/types"
import { breakpoints } from "../utils"

const Container: any = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`
const InnerContainer = styled.div`
  width: 95%;
  border: 1px solid lightgray;
  height: 400px;
  border-radius: 12px;
  @media (max-width: ${breakpoints.tablet}px) {
    display: flex;
    width: 90%;
    flex: 0.95;
    margin: 2px;
    flex-direction: column;
    height: 360px;
  }
  @media (max-width: ${breakpoints.phone}px) {
    display: flex;
    flex: 0.87;
    flex-direction: column;
    height: 360px;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 198px;
`
const Title = styled.div`
  padding: 0px 10px 0px 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  min-height: 50px;
  color: ${props => props.theme.colors.secondary};
  @media (max-width: ${breakpoints.tablet}px) {
    min-height: 0px;
  }
`
const Description = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  padding: 10px;
  padding-bottom: 10px;
  color: ${props => props.theme.colors.secondary};
`
const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
`
const Info = styled(Text)`
  font-size: 12px;
  color: ${props => props.theme.colors.secondary};
  padding: 10px;
  /* margin-top: 18px;
   */
`
// const Separator = styled(GoPrimitiveDot)`
//   margin: 0 5px;
// `

interface Props {
  onClick: Function
  className?: string
}

const DiscoverCard: React.FC<IArticle & Props> = ({
  title,
  description,
  last_updated_at,
  read_time,
  thumbnail,
  onClick,
  className,
}) => {
  return (
    <Container onClick={onClick} className={className}>
      <InnerContainer>
        <ImageWrapper>
          <Img
            fluid={thumbnail.childImageSharp.fluid}
            style={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              margin: "0px",
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
          />
        </ImageWrapper>
        <Info>
          <span>{last_updated_at}</span>
          {/* <Separator /> */}
          <span>{read_time} read</span>
        </Info>
        <Title>{title} </Title>
        <Description>{description}</Description>
      </InnerContainer>
    </Container>
  )
}

export default DiscoverCard
