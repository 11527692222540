import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { HiSpeakerphone } from "react-icons/hi"
import { IVoice } from "../../../models/Voice"
import RedirectLink from "../../RedirectLink"
import SectionTitle from "../../SectionTitle"
import VoiceCard from "./VoiceCard"
import Carousel from "../../Carousel"
import { breakpoints } from "../../../utils"
import { queryAllYaml } from "utils/staticQuery"
import ModalVideo from "react-modal-video"

const Container = styled.div`
  margin-top: 1.5rem;

  & .alice-carousel__stage-item {
    margin: 0px 5px 0px 5px;
    border-radius: 12px;
    border: 1px solid lightgray;
  }
`
const CarouselContainer = styled.div`
  margin-top: 1.5rem;
  & .alice-carousel__stage-item {
    margin-right: 10px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const SpeakerIcon = styled(HiSpeakerphone)`
  width: 2.2rem;
  margin-right: 10px;
`
const MoreLink = styled(RedirectLink)`
  display: block;
  @media (max-width: ${breakpoints.phone}px) {
    display: none;
  }
`
const MobileMoreLink = styled(RedirectLink)`
  display: block;
  margin-top: -15px;
  margin-bottom: 40px;
  text-align: center;
  @media (min-width: ${breakpoints.phone}px) {
    display: none;
  }
`

const WomenVoiceSection = () => {
  const [videoModalIsVisible, setVideoModalIsVisible] = useState(false)
  const [items, setItems] = useState<IVoice[]>([])
  const [videoURL, setVideoURL] = useState("")
  const [maxHeight, setMaxHeight] = useState(0)
  const showVideo = (url: string) => {
    setVideoModalIsVisible(true)
    setVideoURL(url)
  }

  const renderCarouselItems = (items: IVoice[], showVideo: Function) =>
    items.map(item => {
      return (
        <VoiceCard
          {...item}
          maxHeight={maxHeight}
          onClick={() => showVideo(item.videoURL)}
        />
      )
    })
  const { womenVoices } = queryAllYaml()
  useEffect(() => {
    let data: any[] = []
    womenVoices.edges.forEach((voice: any) => {
      const videos = voice.node.videos.map((video: any) => ({
        ...video,
        diagnosis: voice.node.topic,
      }))
      data.push(...videos)
    })
    data.sort((a, b) => {
      if (a.order === b.order) return 0
      if (a.order === undefined || a.order === null) return -1
      if (b.order === undefined || b.order === null) return -1
      if (a.order < b.order) {
        return -1
      }
      return 1
    })
    console.log("data: ", data)

    setItems(data)
    const maxHeightForItem = Math.max.apply(
      null,
      data.map(item => item.title.length)
    )
    setMaxHeight(maxHeightForItem)
  }, [])

  return (
    <Container>
      <Header>
        {/* @ts-ignore */}
        <SectionTitle centerInMobile>
          <SpeakerIcon />
          Women’s Voices
        </SectionTitle>
        <MoreLink text="View more" href={`/womenvoices`} />
      </Header>
      {/* <CarouselContainer> */}
      {items && items.length ? (
        <Carousel items={renderCarouselItems(items, showVideo)} />
      ) : null}
      <MobileMoreLink text="View more" href={`/womenvoices`} />
      {/* </CarouselContainer> */}

      <ModalVideo
        url={videoURL}
        channel="custom"
        autoplay={false}
        isOpen={videoModalIsVisible}
        onClose={() => setVideoModalIsVisible(false)}
      />
    </Container>
  )
}

export default WomenVoiceSection
