import EmbedVideoThumb from "components/EmbedVideoThumb"
import React, { useEffect } from "react"
import styled from "styled-components"
// import { GoPrimitiveDot } from "react-icons/go"
import { breakpoints } from "../../../utils"

const Container = styled.div`

  width: 279px;
  display:flex;
  justify-content: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  height: ${(props: any) => props.$maxHeight + 280}px;
 
  @media (max-width: ${breakpoints.tablet}px) {
    width: 398px;
    height:310px;
    
  }
  
    
  }
`
const InnerContainer = styled.div`
  width: 95%;
  border: 1px solid lightgray;
  border-radius: 12px;
  border-radius: 12px;
   @media (max-width: ${breakpoints.phone}px) {
    width: 87%;
`

const Description = styled.div`
  padding: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;

  color: ${props => props.theme.colors.secondary};
  margin-right: 20px;
`
const Profile = styled.div`
  margin-top: 12px;
  display: flex;
  padding: 10px;
  margin-right: 5px;
  align-items: center;
  position: absolute;
  bottom: 0;
`
const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
`
const ProfileInfo = styled(Text)`
  font-size: 12px;
  color: ${props => props.theme.colors.secondary};
`
// const Separator = styled(GoPrimitiveDot)`
//   margin: 0 5px;
// `

interface Props {
  readonly title: string
  readonly videoURL: string
  readonly thumbnail: any
  readonly name: string
  readonly diagnosis: string
  readonly maxHeight: number
  readonly onClick?: Function
}

const VoiceCard: React.FC<Props> = ({
  title,
  videoURL,
  thumbnail,
  name,
  diagnosis,
  onClick,
  maxHeight,
}) => {
  return (
    <Container $maxHeight={maxHeight}>
      <InnerContainer>
        <EmbedVideoThumb onClick={onClick} imageUrl={thumbnail} />
        <Description>{title}</Description>
        <Profile>
          <ProfileInfo>
            <span>{name}</span>
            {/* <Separator /> */}
            <span>{diagnosis}</span>
          </ProfileInfo>
        </Profile>
      </InnerContainer>
    </Container>
  )
}

export default VoiceCard
