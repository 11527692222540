import { navigate } from "@reach/router"
import { Auth, Amplify } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { PuffLoader } from "react-spinners/"

import { AWS_CONFIG } from "../../../aws-config"
import { WhiteAppButton } from "../buttons"

const OnlyForUser: React.FC<{
  children?: any
  redirectTo: string | null
  fallbackRedirectComponent?: any
  functionalChildren?: (isUser: boolean) => any
}> = ({
  children,
  redirectTo,
  fallbackRedirectComponent,
  functionalChildren,
}) => {
  Amplify.configure(AWS_CONFIG)
  const [showContent, setShowContent] = useState<boolean | null>(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => setShowContent(true))
      .catch(() => setShowContent(false))
  }, [])

  if (showContent === null)
    return (
      <div className="p-1 flex justify-center" title="Loading. Please wait...">
        <WhiteAppButton
          className="bg-gray-300 cursor-default hover:bg-gray-300"
          Icon={<PuffLoader size={16} color={"#003b78"} loading={true} />}
        />
      </div>
    )
  if (showContent === false) {
    if (redirectTo) {
      navigate(redirectTo)
      return null
    }
    if (functionalChildren) {
      return <>{functionalChildren(false)}</>
    }
    if (fallbackRedirectComponent) return <>{fallbackRedirectComponent}</>
    return null
  }
  if (functionalChildren) {
    return <>{functionalChildren(true)}</>
  }
  if (children) return <>{children}</>
  return null
}

export default OnlyForUser
