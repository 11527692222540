import RedirectLink from "../../RedirectLink"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import Carousel from "../../Carousel"
import { breakpoints } from "../../../utils"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Container = styled.div`
  border-radius: 12px;
  margin-top: 50px;
  /* margin: 50px 16px 0; */
  // fix: https://gitlab.com/mchri/early-menopause-app/-/issues/44
  height: auto;

  overflow: hidden;
  @media (max-width: ${breakpoints.tablet}px) {
    /* margin: 20px 0 0; */
    height: auto;
    border-radius: 12px;
    margin: 50px 16px 0;
  }
`
const RowContainer = styled.div`
  width: 90%;
  background-color: green;
  border-radius: 12px;
  justify-content: center;
`
const Section = styled(Col)`
  padding: 0;
`
const InfoWrapper = styled.div`
  background: #163a74;
  height: 470px;
  color: white;
  padding: 48px;
  font-family: Open Sans;
  font-style: normal;
  color: #fff;
  display: flex;

  flex-direction: column;
  @media (max-width: ${breakpoints.tablet}px) {
    height: auto;
    border-radius: 12px;
  }
`
const Nav = styled.div`
  display: none;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    display: flex;
  }
`
const MobileNav = styled.div`
  display: none;
  @media (max-width: ${breakpoints.tabletLandscape - 1}px) {
    display: flex;
  }
`
const Dot = styled.div`
  border-radius: 100%;
  background: ${(props: any) =>
    props.active ? "white" : "rgba(0, 0, 0, 0.25)"};
  width: 8px;
  height: 8px;
  margin-right: 8px;
  cursor: pointer;
`
const CoverImage = styled(Img)`
  /* width: 100%; */
  display: flex;
  height: 470px;
  object-fit: contain;
  /* object-position: 50% 50%; */
  @media (max-width: ${breakpoints.tablet}px) {
    height: 300px;
    width: 400px;
    object-fit: contain;
  }
`
const Title = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  margin-top: 18px;
  margin-bottom: 12px;
  width: 90%;
  color: ${props => props.theme.colors.white};
`
const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: ${props => props.theme.colors.white};
`
const Footer = styled.div`
  margin-top: auto;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const IconContainer = styled.div`
  border-radius: 12px;
`
const Icon = styled.img`
  position: absolute;
  top: 25px;
  right: 36px;
  height: 96px;
`

const infoCards = [
  {
    id: 1,
    title: "Surgical menopause",
    description:
      "Menopause means the final menstrual period. The average age of menopause is around 51 years, but most women will start to notice menopausal symptoms from around 47 years. This may be noticed as the onset of hot flushes, night sweats or vaginal dryness or a change in menstrual periods to more infrequent and sometimes heavier menstrual bleeding. Removal of both ovaries (bilateral ophorectomy) before the normal menopause is called “surgical menopause”.",
    linkText: "Learn more",
    linkUrl: "/what-is-surgical-menopause ",
    coverImage: "surgical-menopause1.jpeg",
    icon: "bulb.inline.png",
  },
  {
    id: 2,
    title: "Early menopause due to chemotherapy and radiotherapy",
    description:
      "Chemotherapy is usually administered as part of cancer treatment but may also be given to women with severe connective tissue disorders such as systemic lupus erythematosus or kidney disease such as Wegener’s granulomatosis. Survival rates for many cancers in women of reproductive age are increasing. However, many treatments used to combat the disease carry a substantial risk for future infertility. Chemotherapy is toxic to the ovaries causing loss of eggs and destruction of the supporting ovarian tissue. This may cause temporary cessation of menstrual periods or early menopause which can develop quickly or gradually.",
    linkText: "Learn more",
    linkUrl: "/early-menopause-due-to-chemotherapy-radiotherapy",
    coverImage: "voice-1.png",
    icon: "bulb.inline.png",
  },
  {
    id: 3,
    title: "Questions to discuss with your doctor",
    description:
      "You will see a list of questions that you could ask your doctor or health professional. Select the questions most relevant for you that you would like to ask during your next appointment.",
    linkText: "Get started",
    linkUrl: "/qpl",
    coverImage: "voice-2.jpeg",
    icon: "bulb.inline.png",
  },
  {
    id: 4,
    title: "Self-assessment",
    description:
      "You will be prompted to answer a few simple questions. Based on your answers we will provide some guidance on the next steps to take. ",
    linkText: "Take Quiz",
    linkUrl: "/self-assessment",
    coverImage: "voice-3.jpeg",
    icon: "bulb.inline.png",
  },
  {
    id: 5,
    title: "Manage my early menopause",
    description:
      "You will find information on how to manage your lifestyle with early menopause and different options on how to help reduce the symptoms.",
    linkText: "Learn more",
    linkUrl: "/manage",
    coverImage: "voice-4.jpeg",
    icon: "bulb.inline.png",
  },
]

const infoCarouselItem = "info-carousel-item"

const InfoSection = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  const query = useStaticQuery(graphql`
    query {
      info_1: file(relativePath: { eq: "surgical-menopause1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      info_2: file(relativePath: { eq: "voice-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      info_3: file(relativePath: { eq: "voice-2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      info_4: file(relativePath: { eq: "voice-3.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      info_5: file(relativePath: { eq: "voice-4.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    setTimeout(() => {
      const items = document.querySelectorAll(`.${infoCarouselItem}`)
      items.forEach(item => {
        item.parentElement?.setAttribute("style", "width:100%")
      })
    }, 1000)
  }, [])

  const gotoSlide = (index: number) => () => {
    setActiveIndex(index)
  }
  const onSlideChanged = ({ item }: any) => {
    setActiveIndex(item)
  }

  const items = infoCards.map(card => {
    const imgSrc = query[`info_${card.id}`].childImageSharp.fluid
    return (
      <IconContainer>
        {/* @ts-ignore */}
        <CoverImage fluid={imgSrc} className={infoCarouselItem} key={card.id} />
      </IconContainer>
    )
  })

  const activeItem = infoCards[activeIndex]
  const iconImgSrc = require(`../../../images/${activeItem.icon}`)

  const renderNavDots = () =>
    items.map((item, i) => (
      <Dot
        onClick={gotoSlide(i)}
        // @ts-ignore
        active={i === activeIndex}
        key={i.toString()}
      />
    ))

  return (
    <Container>
      <Row>
        <Section sm={12} md={6} style={{ position: "relative" }}>
          <Carousel
            disableResponsive
            hideNavBtns
            items={items}
            activeIndex={activeIndex}
            onSlideChanged={onSlideChanged}
            autoPlay
            infinite
            autoHeight={true}
          />
        </Section>
        <Section sm={12} md={6}>
          <InfoWrapper>
            <Icon src={iconImgSrc} alt="bulb inline" />

            <Nav>{renderNavDots()}</Nav>
            <Title>{activeItem.title}</Title>
            <Description>{activeItem.description}</Description>
            <Footer>
              <RedirectLink
                text={activeItem.linkText}
                href={activeItem.linkUrl}
                color="white"
              />
              <MobileNav>{renderNavDots()}</MobileNav>
            </Footer>
          </InfoWrapper>
        </Section>
      </Row>
    </Container>
  )
}

export default InfoSection
