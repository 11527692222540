import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { AskRedirectLink } from "../../RedirectLink"
import SectionTitle from "../../SectionTitle"
import { BiMessage } from "react-icons/bi"
import { breakpoints } from "../../../utils"
import getProfileFromSessionStorage from "components/QueryUserProfile/getProfileFromSessionStorage"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const cards = [
  {
    title: "Your questions answered",
    description:
      "You will see a list of questions that you could ask your doctor or health professional. Select the questions most relevant for you that you would like to ask during your next appointment.",
    linkText: "Explore questions",
    linkUrl: "/qpl",
    coverImage: "ask-1.png",
    loginRequired: true,
  },
  {
    title: "Self-assessment",
    description:
      "You will be prompted to answer a few simple questions. Based on your answers we will provide some guidance on the next steps to take.",
    linkText: "Take self-assessment",
    linkUrl: "/self-assessment",
    coverImage: "ask-2.png",
    loginRequired: true,
  },
  {
    title: "Manage my early menopause",
    description:
      "In this section you will find information on how to manage the symptoms as well as recommendations that may help you optimise your lifestyle.",
    linkText: "Let's get started",
    linkUrl: "/manage",
    coverImage: "ask-3.png",
    loginRequired: false,
  },
]

const Container = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
`
const RowContainer = styled.div`
  @media (max-width: ${breakpoints.tablet}px) {
    width: 90%;
  }
`
const ChatIcon = styled(BiMessage)`
  width: 2.2rem;
  margin-right: 10px;
`
const AskCard = styled(Card)`
  border: 1px solid lightgray;
  border-radius: 12px;
  margin-bottom: 50px;
  @media (min-width: ${breakpoints.phone}px) {
    min-width: 200px;
  }
  @media (min-width: ${breakpoints.tablet}px) {
    min-width: 280px;
  }
`
const Title = styled(SectionTitle)`
  margin-bottom: 20px;
  color: ${props => props.theme.colors.secondary};
`
// @ts-ignore
AskCard.Img = styled(Card.Img)`
  margin-bottom: 16px;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px;
  object-fit: cover;
`
AskCard.Body = styled(Card.Body)`
  height: 226px;
  padding: 0;
`
AskCard.Title = styled(Card.Title)`
  padding: 0px 15px 0px 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: ${props => props.theme.colors.secondary};
`
AskCard.Text = styled(Card.Text)`
  padding: 0px 15px 0px 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
`

const AskSection: React.FC = () => {
  const user = getProfileFromSessionStorage()
  const query = useStaticQuery(graphql`
    query {
      ask_1: file(relativePath: { eq: "ask-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 444) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ask_2: file(relativePath: { eq: "ask-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 444) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ask_3: file(relativePath: { eq: "ask-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 444) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      {/* @ts-ignore */}

      <RowContainer>
        <Title centerInMobile>
          <ChatIcon />
          Ask
        </Title>
        <Row>
          {cards.map((card, i) => {
            const coverImgSrc = query[`ask_${i + 1}`].childImageSharp.fluid
            return (
              <Col sm="12" md="6" lg="4" key={i.toString()}>
                <AskCard key={card.title}>
                  <Img
                    style={{
                      borderTopRightRadius: "12px",
                      borderTopLeftRadius: "12px",
                    }}
                    fluid={coverImgSrc}
                  />

                  <AskCard.Body>
                    <AskCard.Title style={{ marginTop: "16px" }}>
                      {card.title}
                    </AskCard.Title>
                    <AskCard.Text>{card.description}</AskCard.Text>
                    {card.loginRequired && !user ? (
                      <AskRedirectLink
                        text={card.linkText}
                        href={`/login?returnUrl=${encodeURIComponent(
                          card.linkUrl
                        )}`}
                      />
                    ) : (
                      <AskRedirectLink
                        text={card.linkText}
                        href={card.linkUrl}
                      />
                    )}
                  </AskCard.Body>
                </AskCard>
              </Col>
            )
          })}
        </Row>
      </RowContainer>
    </Container>
  )
}

export default AskSection
