import { navigate } from "gatsby"
import React from "react"

import { Profile_InternalLink } from "../../utils/urls"
import { UserType } from "api/types"
import QueryUserProfile from "./index"

const OnlyWithProfile: React.FC<{
  children?: any
  childrenWithProfileData?: (data: UserType | null) => any
  noRedirectToProfilePage?: boolean
  hideLoader?: boolean
}> = ({
  children,
  childrenWithProfileData,
  noRedirectToProfilePage,
  hideLoader,
}) => {
  return (
    <QueryUserProfile hideLoader={hideLoader}>
      {data => {
        if (!data && !noRedirectToProfilePage) {
          navigate(Profile_InternalLink)
        }
        if (data && !noRedirectToProfilePage) {
          navigate(Profile_InternalLink)
        }
        if (childrenWithProfileData) {
          return <>{childrenWithProfileData(data)}</>
        }
        return <>{children}</>
      }}
    </QueryUserProfile>
  )
}

export default OnlyWithProfile
