import React, { useState } from "react"
// @ts-ignore
import ModalVideo from "react-modal-video"
import { iAppButton } from "../types"
import "./video-modal.css"

interface iVideoModalButton {
  title?: any
  Icon?: object
  className?: string
  iconOnRight?: boolean
  [id: string]: any
}
export const isBrowser = () => typeof window !== "undefined"
export const attachVideoModal = (
  Component: any,
  channelType: string,
  videoSrc: string
) => {
  return () => {
    if (!isBrowser()) return <></>
    const [isOpen, setIsOpen] = useState(false)
    return (
      <>
        <ModalVideo
          channel={channelType}
          autoplay
          isOpen={isOpen}
          videoId={videoSrc}
          onClose={() => setIsOpen(false)}
        />
        <div className="flex">
          <div onClick={() => setIsOpen(true)}>{Component}</div>
        </div>
      </>
    )
  }
}

const makeVideoModalAppButton = (
  ButtonComponent: React.FC<iAppButton>,
  channelType: string,
  videoSrc: string,
  buttonConfig: iVideoModalButton
) => {
  return attachVideoModal(
    <ButtonComponent {...buttonConfig} />,
    channelType,
    videoSrc
  )
}

export default makeVideoModalAppButton
