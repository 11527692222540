import styled from "styled-components"
import { breakpoints } from "../utils"

const Title: any = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.secondary};
  @media (max-width: ${breakpoints.phone}px) {
    justify-content: ${(props: any) => {
      return props.centerInMobile ? "center" : "flex-start"
    }};
    margin: 1rem auto;
  }
`

export default Title
