import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import { FaRegPlayCircle, FaVideo } from "../../../AppReactIcons"
import SiteContainer from "../../SiteContainer"
import makeVideoModalAppButton, {
  attachVideoModal,
} from "../../VideoModalButton/makeVideoModalAppButton"
import { DarkAppButton } from "../../buttons"

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "askEm.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const WhatIsEm = () => {
  const ThumbnailVideoButton = attachVideoModal(
    <div
      title="Watch animation"
      className="cursor-pointer absolute top-0 left-0 w-full h-full site-border-radius flex items-center justify-center"
      style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
    >
      <p className="text-5xl text-center text-white hover:text-gray-300">
        <FaRegPlayCircle />
      </p>
    </div>,
    "youtube",
    "BszMBDSVOUE"
  )

  return (
    <section className="py-4 sm:py-6">
      <SiteContainer>
        <div>
          <StaticQuery query={query}>
            {data => (
              <div className="site-border-radius overflow-hidden h-full relative mt-0">
                <Img
                  className="h-full"
                  fluid={data.placeholderImage.childImageSharp.fluid}
                />
                <ThumbnailVideoButton />
              </div>
            )}
          </StaticQuery>
        </div>
      </SiteContainer>
    </section>
  )
}

export default WhatIsEm
